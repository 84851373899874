import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import {plus} from 'react-icons-kit/icomoon/plus'
import {checkmark} from 'react-icons-kit/icomoon/checkmark'
import withReactContent from 'sweetalert2-react-content'
import {cross} from 'react-icons-kit/icomoon/cross'
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrameF2 from '../components/FrameF2'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import Dialog from '@material-ui/core/Dialog';
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, getData } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
import { colors } from '../utils/vars';
import { SettingsRemoteTwoTone, Subway } from '@mui/icons-material';

const pageName='encaminhamentos'

const swal = withReactContent(Swal)

function Encaminhamentos(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [pac, setPac] = useState()
  const [checked ,setChecked] = useState([])
  const [enableEdit, setenableEdit] = useState('N')
  const [selected, setSelected] = useState([])
  const [enableDel, setenableDel] = useState('N')
  const [cad, setCad] = useState(0)
  const [dia, setDia] = useState(0)
  const [horario, setHorario] = useState(0)
  const [comboHorario, setComboHorario] = useState([{}])
  const [comboSede, setComboSede] = useState([{}])
  const [comboDia, setComboDia] = useState([{}])
  const [sede, setSede] = useState(0)
  const [motivo_aceite, setMotivo_aceite] = useState('')
  const [motivo_nao_efetivacao, setMotivo_nao_efetivacao] = useState('')
  const [showModalAceitar, setShowModalAceitar] = useState(false)
  const [showModalConfirmar, setShowModalConfirmar] = useState(false)
  const [showModalRecusar, setShowModalRecusar] = useState(false)
  const [row, setRow] = useState()
  // const [comboTerapeuta, setComboTerapeuta] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }])
  const [comboStatus, setComboStatus] = useState([{ value: 'P', display: 'Aguardando Aceite' }, 
                                                  { value: 'A', display: 'Aguardando Confirmação de Atendimento' }, 
                                                  { value: 'R', display: 'Pendente de Reserva de Sala' },
                                                  { value: 'C', display: 'concluído' },
                                                  { value: 'N', display: 'Recusado' },
                                                  { value: 'X', display: 'Cancelado' },
                                                  ])

  const columns = [
    { id: 'NUMERO_PRONTUARIO', numeric: false, label: 'Nº Prontuário', align: 'left', minWidth: 200 },
    { id: 'PACIENTE', numeric: false, label: 'Paciente', align: 'left', minWidth: 150 },
    { id: 'CASE', numeric: false, label: 'Status', align: 'left', minWidth: 150 }

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      // setComboTerapeuta(await api.getComboData('terapeuta'))
      setComboStatus([{ VALUE: 'P', DISPLAY: 'Aguardando Aceite' }, 
      { VALUE: 'A', DISPLAY: 'Aguardando Confirmação de Atendimento' }, 
      { VALUE: 'R', DISPLAY: 'Pendente de Reserva de Sala' },
      { VALUE: 'C', DISPLAY: 'concluído' },
      { VALUE: 'N', DISPLAY: 'Recusado' },
      { VALUE: 'X', DISPLAY: 'Cancelado' },
      ])
      setComboHorario([{DISPLAY: '08:00', VALUE: '08:00'},
        {DISPLAY: '08:30', VALUE: '08:30'},
        {DISPLAY: '09:00', VALUE: '09:00'},
        {DISPLAY: '09:30', VALUE: '09:30'},
        {DISPLAY: '10:00', VALUE: '10:00'},
        {DISPLAY: '10:30', VALUE: '10:30'},
        {DISPLAY: '11:00', VALUE: '11:00'},
        {DISPLAY: '11:30', VALUE: '11:30'},
        {DISPLAY: '12:00', VALUE: '12:00'},
        {DISPLAY: '12:30', VALUE: '12:30'},
        {DISPLAY: '13:00', VALUE: '13:00'},
        {DISPLAY: '13:30', VALUE: '13:30'},
        {DISPLAY: '14:00', VALUE: '14:00'},
        {DISPLAY: '14:30', VALUE: '14:30'},
        {DISPLAY: '15:00', VALUE: '15:00'},
        {DISPLAY: '15:30', VALUE: '15:30'},
        {DISPLAY: '16:00', VALUE: '16:00'},
        {DISPLAY: '16:30', VALUE: '16:30'},
        {DISPLAY: '17:00', VALUE: '17:00'},
        {DISPLAY: '17:30', VALUE: '17:30'},
        {DISPLAY: '18:00', VALUE: '18:00'},
        {DISPLAY: '18:30', VALUE: '18:30'},
        {DISPLAY: '19:00', VALUE: '19:00'},
        {DISPLAY: '19:30', VALUE: '19:30'},
        {DISPLAY: '20:00', VALUE: '20:00'},
        {DISPLAY: '20:30', VALUE: '20:30'},
        {DISPLAY: '21:00', VALUE: '21:00'},
        {DISPLAY: '21:30', VALUE: '21:30'},
        {DISPLAY: '22:00', VALUE: '22:00'},
        {DISPLAY: '22:30', VALUE: '22:30'}])
      let sedes = await api.getComboData('sedes')
      setComboSede([{DISPLAY: 'Indiferente', VALUE: null}, ...sedes])

      setComboDia([
        {DISPLAY:'Segunda', VALUE: 0},
        {DISPLAY:'Terça', VALUE: 1},
        {DISPLAY:'Quarta', VALUE: 2},
        {DISPLAY:'Quinta', VALUE: 3},
        {DISPLAY:'Sexta', VALUE: 4},
        {DISPLAY:'Sábado', VALUE: 5},
      ])
      
      setenableAdd(await api.getAcessos(pageName, 'Inclusao'))
      setenableEdit(await api.getAcessos(pageName, 'Alteracao'))
      setenableDel(await api.getAcessos(pageName, 'Exclusao'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      console.log(query)
      query.usu_logado = auth.user
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        console.log(pageName)
        getData(event, setData, setFiltered, pageName)
      }
    }

    fetchData()
  }, [])

  function getExtraData(e) {
   return
  }

  function recusar(e) {
    e.preventDefault()
    // if (!motivo_nao_efetivacao){
    //   return swal.fire('Motivo inválido!', "Preencha o motivo da recusa.", 'warning')
    // }
    if(selected.length>1){
      return swal.fire('Multiplos registros!', "Selecione um registro por vez.", 'warning')
    }
    console.log(selected.length)
    if(selected.length==0){
      return swal.fire('Nenhum registro selecinado!', "Selecione um registro.", 'warning')
    }
    let r =data.filter(i=> {return i.PK_ECW == selected}) 
    setRow(data.filter(i=> {return i.PK_ECW == selected}))
    console.log(r[0])
    if((r[0].CASE.trim()) == "AGUARDANDO ACEITE" || (row[0].CASE.trim()) == "AGUARDANDO CONFIRMAÇÃO DE ATENDIMENTO"){
      
      setShowModalRecusar(true)
    }else {
        swal.fire('Status incoerente!', "Ação permitida somente para registros com status 'Pendente Encaminhamento'!", 'warning')
      }
  }
  function escondeModalRecusar (e) {
    e.preventDefault()
    setShowModalRecusar(false)
    setMotivo_nao_efetivacao('')
  }

  function escondeModalConfirmar (e) {
    e.preventDefault()
    setShowModalConfirmar(false)
    setDia()
    setHorario()
    setSede()
  }

  function confirmarEncaminhamento (e) {
    e.preventDefault()
    console.log(sede)
    if(sede && dia && horario){
      swal.fire({title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `
          <p style='color: ${colors.info900}'>Deseja tornar atendimento recorrente?<p>
        `,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: sede != 3,
      showDenyButton: true,
      confirmButtonColor: '#FF6900',
      cancelButtonColor: '#FF6900',
      denyButtonColor: '#FF6900',
      confirmButtonText: 'Quinzenal',
      showCloseButton: true,
      denyButtonText: 'Semanal',
      cancelButtonText: 'Sem recorrência'
    }).then((result) => {
      let periodo = ''
      let recorrencia = false
      if (result.isConfirmed){
        periodo = 'Q'
        recorrencia = true
      } else if (result.isDenied) {
        periodo = 'S'
        recorrencia = true
      } else if (result.isDismissed && result.dismiss === 'cancel') {
        recorrencia = false
      } else {
        return swal.close()
      }

      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      setShowModalConfirmar(false)
      
        
          api.post('/encaminhamentos/confirmar', {sede, dia, horario, pk_ecw: selected, recorrente: recorrencia, periodo: periodo}).then(r =>{
            if(r.status == 200){
              swal.fire('Sucesso!', 'Encaminhamento confirmado.', 'success')
            }
          }).catch(e=>{
            swal.fire('Erro!', 'Um erro inesperado ocorreu', 'warning')
            // setShowModalConfirmar(false)
          })

      })

      // swal.fire({
      //   html: <FrLoading text="Carregando..." />,
      //   showConfirmButton: false,
      //   allowOutsideClick: false,
      //   allowEscapeKey: false
      // })
  

      // swal.close()

 

    } else{
      swal.fire('Preenchimento Obrigatorio!', 'Preencha todos os campos', 'warning')
    }
  }

  function recusarEncaminhamento (e) {
    e.preventDefault()
    console.log(selected, data)
    if(motivo_nao_efetivacao){
      
        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
    
        api.post('/encaminhamentos/recusar', {motivo_nao_efetivacao: motivo_nao_efetivacao, pk_ecw: selected}).then(r =>{
          if(r.status == 200){
            swal.fire('Sucesso!', 'Encaminhamento recusado.', 'success').then(r=> {
              setShowModalRecusar(false)
            })
          }
        }).catch(e=>{
          swal.fire('Erro!', 'Um erro inesperado ocorreu', 'warning')
        })
  
        swal.close()

 

    } else{
      swal.fire('Campo obrigatório!', 'Preencha o campo motivo.', 'warning')
    }
  }

  function handleGetData(e){
    getData(e, setData, setFiltered, pageName, ()=>{}, false, filter)
  }

  function confirmar (e) {
    e.preventDefault()
    if(selected.length==0){
      return swal.fire('Nenhum registro selecinado!', "Selecione um registro.", 'warning')
    }
    if(selected.length>1){
      return swal.fire('Multiplos registros!', "Selecione um registro por vez.", 'warning')
    }
    let r =data.filter(i=> {return i.PK_ECW == selected}) 
    setRow(data.filter(i=> {return i.PK_ECW == selected}))
    console.log(r[0])
    if(r[0].CASE.trim() == "AGUARDANDO CONFIRMAÇÃO"){
      
      setShowModalConfirmar(true)
    }else {
        swal.fire('Status incoerente!', "Ação permitida somente para registros com status 'Aguardando Confirmação'!", 'warning')
      }
  }


  function aceitar (e) {
    e.preventDefault()
    if(selected.length==0){
      return swal.fire('Nenhum registro selecinado!', "Selecione um registro.", 'warning')
    }
    if(selected.length>1){
      return swal.fire('Multiplos registros!', "Selecione um registro por vez.", 'warning')
    }
    let r =data.filter(i=> {return i.PK_ECW == selected}) 
    setRow(data.filter(i=> {return i.PK_ECW == selected}))
    console.log(r[0])
    if(r[0].CASE.trim() == "AGUARDANDO ACEITE"){
      
      setShowModalAceitar(true)
    }else {
        swal.fire('Status incoerente!', "Ação permitida somente para registros com status 'Pendente Encaminhamento'!", 'warning')
      }
  }

  function escondeModalAceitar (e) {
    e.preventDefault()
    setShowModalAceitar(false)
    setMotivo_aceite('')
  }

  function aceitarEncaminhamento (e) {
    e.preventDefault()
    console.log(selected, data)
    if(motivo_aceite){
      
      escondeModalAceitar(e)
        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
    
        api.post('/encaminhamentos/aceitar', {motivo_aceite: motivo_aceite, pk_ecw: selected}).then(r =>{
          if(r.status == 200){
            swal.fire('Sucesso!', 'Encaminhamento aceito.', 'success')
          }
        }).catch(e=>{
          swal.fire('Erro!', 'Um erro inesperado ocorreu', 'warning')
        })
  
        swal.close()

 

    } else{
      swal.fire('Campo obrigatório!', 'Preencha o campo observações', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/intcc/encaminhamentos?usu_logado='+auth.user);
    document.getElementById('searchForm').reset()
    setFilter({usu_logado: auth.user})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    switch (e.target.name) {     
      case 'motivo_aceite': setMotivo_aceite(e.target.value)
      break;
      case 'motivo_nao_efetivacao': setMotivo_nao_efetivacao(e.target.value)
      break;
      case 'horario': setHorario(e.target.value)
      break;
      case 'dia': setDia(e.target.value)
      break;
      case 'sede': setSede(e.target.value)
      break;

      case 'fk_cad':   
      case 'cad': {
        auxValues['fk_cad'] = e.target.value;
        setCad(e.target.value)
        break
      }
      default: auxValues[e.target.name] = e.target.value;
    }
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Encaminhamentos</h3>
          <UserOptions  />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={handleGetData}>
            <div>
            <FrTextInput
              value={filter.usu_logado || ''}
              style={{ width: 330 }}
              name="usu_logado"
              id="usu_logado"
              color="#389492"
              label="usu_logado"
              variant="outlined"
              size="small"
              hidden
              onChange={handleChange}
            />
            <FrTextInput
                value={filter.nome || ''}
                style={{ width: 330 }}
                name="nome"
                id="nome"
                color="#389492"
                label="Paciente"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

            <FrSelect
              value={filter.status || ''}
              style={{ width: 340 }}
              name="status"
              id="status"
              label='Status'
              data={comboStatus}
              onChange={handleChange}
            />
            {/* <FrTextInput
                value={filter.fk_cad || ''}
                style={{ width: 330 }}
                name="fk_cad"
                id="fk_cad"
                color="#389492"
                label="Terapeuta"
                variant="outlined"
                size="small"
                hidden
                onChange={handleChange}
              />
            <FrTextInput
                value={filter.prontuario || ''}
                style={{ width: 330 }}
                name="prontuario"
                id="prontuario"
                color="#389492"
                label="Nº Prontuário"
                variant="outlined"
                size="small"
                onChange={handleChange}
              /> */}
              
              

            </div>
            <div>
              <button onClick={handleGetData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              <button onClick={recusar}>
                <Icon icon={cross} size={14} />
                Recusar
              </button>
              <button onClick={aceitar}>
                <Icon icon={plus} size={14} />
                Aceitar
              </button>
              <button onClick={confirmar}>
                <Icon icon={checkmark} size={16} />
                confirmar
              </button>
              {/* {enableAdd !== 'S' ? '' :
                <Link to="/intcc/encaminhamentos/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>} */}
            </div>
          </form>
          <div>
            <Dialog aria-labelledby="simple-dialog-title" open={showModalAceitar} fullWidth={false} maxWidth={'lg'} >
              <div style={{ padding: '20px', paddingTop: '40px', backgroundColor:'var(--cor-tema)'  }}>

                <div className="defaultHeader">

                  <h3>Aceitar Encaminhamento</h3>
                </div>
                <br />


                <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start', backgroundColor:'var(--cor-tema)' }}>

                  <FrTextInput
                    value={motivo_aceite || ''}
                    style={{ width: 330 }}
                    name="motivo_aceite"
                    id="motivo_aceite"
                    color="#389492"
                    label="Observações"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                  />
                  

                  

                </div>
              </div>
              <div className="FrModalFooter">
                <div className="FrModalButtonsDiv" >
                  <button onClick={aceitarEncaminhamento}> Confirmar </button>
                  <button onClick={escondeModalAceitar}> Sair </button>
                </div>
              </div>
            </Dialog>
          </div>
          <div>
            <Dialog aria-labelledby="simple-dialog-title" open={showModalConfirmar} fullWidth={false} maxWidth={'lg'} >
              <div style={{ padding: '20px', paddingTop: '40px', backgroundColor:'var(--cor-tema)'  }}>

                <div className="defaultHeader">

                  <h3>Confirmar Encaminhamento</h3>
                </div>
                <br />


                <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start', backgroundColor:'var(--cor-tema)' }}>

                  <FrSelect
                    value={dia || ''}
                    style={{ width: 340 }}
                    name="dia"
                    id="dia"
                    label='Dia'
                    data={comboDia}
                    onChange={handleChange}
                  />  
                  <FrSelect
                    value={horario || ''}
                    style={{ width: 340 }}
                    name="horario"
                    id="horario"
                    label='Horário'
                    data={comboHorario}
                    onChange={handleChange}
                  />
                  <FrSelect
                    value={sede || ''}
                    style={{ width: 340 }}
                    name="sede"
                    id="sede"
                    label='Sede'
                    data={comboSede}
                    onChange={handleChange}
                  />
                  

                  

                </div>
              </div>
              <div className="FrModalFooter">
                <div className="FrModalButtonsDiv" >
                  <button onClick={confirmarEncaminhamento}> Confirmar </button>
                  <button onClick={escondeModalConfirmar}> Sair </button>
                </div>
              </div>
            </Dialog>
          </div>
          <div>
            <Dialog aria-labelledby="simple-dialog-title" open={showModalRecusar} fullWidth={false} maxWidth={'lg'} >
              <div style={{ padding: '20px', paddingTop: '40px', backgroundColor:'var(--cor-tema)'  }}>

                <div className="defaultHeader">

                  <h3>Recusar Encaminhamento</h3>
                </div>
                <br />


                <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start', backgroundColor:'var(--cor-tema)' }}>

                  <FrTextInput
                    value={motivo_nao_efetivacao || ''}
                    style={{ width: 330 }}
                    name="motivo_nao_efetivacao"
                    id="motivo_nao_efetivacao"
                    color="#389492"
                    label="Motivo"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                  />
                  

                  

                </div>
              </div>
              <div className="FrModalFooter">
                <div className="FrModalButtonsDiv" >
                  <button onClick={recusarEncaminhamento}> Confirmar </button>
                  <button onClick={escondeModalRecusar}> Sair </button>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable 
            regName={pageName} 
            columns={columns} 
            mostraCheck 
            searched={filtered} 
            codeName="PK_ECW" 
            page={pageName}  
            selected={selected} 
            setSelected={setSelected} 
            data={data} 
            enableEdit={enableEdit} 
            enableDel={false} 
          />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Encaminhamentos;
