import axios from 'axios';

// const backendUrl = 'http://localhost:20657' //local escritorio
// const backendUrl = 'http://192.168.0.254:20655' //homologacao servidor delphus
// const backendUrl = 'https://apps.delphus.inf.br:20666' //homologação
var backendUrl = 'https://apps.delphus.inf.br:20651' //produção
if(window.location.origin == 'http://localhost:3000') {
    backendUrl = 'http://localhost:20657'
} else if(window.location.origin == 'https://apps.delphus.inf.br' ){
    backendUrl = 'https://apps.delphus.inf.br:20666'
}

const api = axios.create({
    baseURL: backendUrl + `/api/`
});

api.getComboData = async (table, parameter) => {
    return new Promise(async (resolve, reject) => {
        console.log('Searching for parameter ' + parameter)
        await api.get('/' + table + '/combo' + (parameter ? '/' + parameter : '')).then(async r => {
            console.log(r.data)
            resolve(r.data)
        })
    })
}

api.getComboQuery = async (table, parameter) => {
    return new Promise(async (resolve, reject) => {
        await api.get('/' + table + '/combo' + parameter).then(async r => {
            resolve(r.data)
        })
    })
}

api.getAcessos = async (tela, opcao) => {
    return new Promise(async (resolve, reject) => {
        // console.log('Searching for parameter '+tela+' '+opcao)
        let auth = JSON.parse(localStorage['authIntcc']);
        resolve('S')

        //     await api.get('/grupo_usuarios/permissoes?tela=' + tela + '&pk_usu=' + auth.user + '&acao=' + opcao).then(async r => {
        //         if (opcao === 'Consulta') {
        //             try {
        //                 resolve(r.data[0].Consulta)
        //             }
        //             catch { resolve('N') }
        //         }

        //         if (opcao === 'Inclusao') {
        //             try {
        //                 resolve(r.data[0].Inclusao)
        //             }
        //             catch { resolve('N') }
        //         }

        //         if (opcao === 'Alteracao') {
        //             try {
        //                 resolve(r.data[0].Alteracao)
        //             }
        //             catch { resolve('N') }
        //         }

        //         if (opcao === 'Exclusao') {
        //             try {
        //                 resolve(r.data[0].Exclusao)
        //             }
        //             catch { resolve('N') }
        //         }

        //         // resolve(r.data)
        //     })
    })
}


api.getListData = async (table, parameter) => {
    return new Promise(async (resolve, reject) => {
        console.log('Searching for parameter ' + parameter)
        await api.get('/' + table + '/lista' + (parameter ? '/' + parameter : '')).then(async r => {
            console.log(r.data)
            resolve(r.data)
        })
    })
}

api.getImage = async (residente, image) => {
    return new Promise(async (resolve, reject) => {
        if (residente && image) {
            console.log(backendUrl + '/static/upload/residente/' + residente + '/' + image)
            axios.get(backendUrl + '/static/upload/residente/' + residente + '/' + image).then(r => {
                resolve(backendUrl + '/static/upload/residente/' + residente + '/' + image)
            }).catch(e => resolve(backendUrl + '/static/upload/no-image.jpg'))
        } else resolve(backendUrl + '/static/upload/no-image.jpg')
    })
}

api.noImageUrl = backendUrl + '/static/upload/no-image.jpg'
api.backendUrl = backendUrl

export default api