import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrDatePicker from '../components/FrDatePicker'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrTablePermissions from '../components/FrTablePermissions'
import {colors} from '../utils/vars'

import api from '../services/api'
import { getData, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { Hidden } from '@material-ui/core';

const swal = withReactContent(Swal)

function RegEncaminhamentos(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState([{}])
  const [sabado, setSabado] = useState('S')
  const [selected, setSelected] = useState([])
  const [dataChi, setDataChi] = useState([{}])
  const [selectedChi, setSelectedChi] = useState([])
  const [sabadoChi, setSabadoChi] = useState('S')
  const [dataPli, setDataPli] = useState([{}])
  const [selectedPli, setSelectedPli] = useState([])
  const [sabadoPli, setSabadoPli] = useState('S')
  const [dataOn, setDataOn] = useState([{}])
  const [selectedOn, setSelectedOn] = useState([])
  const [sabadoOn, setSabadoOn] = useState('S')
  const [updateType, setUpdateType] = useState(false)
  const [comboSedes, setComboSedes] = useState([{ value: 'S', display: 'Sim' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const [fkSed, setFkSed] = useState()

  const columns = [
    { id: 'name', numeric: false, label: 'Dia Semana', align: 'left', minWidth: 150 },
    { id: '08:00', numeric: false, label:  '8:00', align: 'left', minWidth: 20 },
    { id: '08:30', numeric: false, label:  '8:30', align: 'left', minWidth: 20 },
    { id: '09:00', numeric: false, label:  '9:00', align: 'left', minWidth: 20 },
    { id: '09:30', numeric: false, label:  '9:30', align: 'left', minWidth: 20 },
    { id: '10:00', numeric: false, label: '10:00', align: 'left', minWidth: 20 },
    { id: '10:30', numeric: false, label: '10:30', align: 'left', minWidth: 20 },
    { id: '11:00', numeric: false, label: '11:00', align: 'left', minWidth: 20 },
    { id: '11:30', numeric: false, label: '11:30', align: 'left', minWidth: 20 },
    { id: '12:00', numeric: false, label: '12:00', align: 'left', minWidth: 20 },
    { id: '12:30', numeric: false, label: '12:30', align: 'left', minWidth: 20 },
    { id: '13:00', numeric: false, label: '13:00', align: 'left', minWidth: 20 },
    { id: '13:30', numeric: false, label: '13:30', align: 'left', minWidth: 20 },
    { id: '14:00', numeric: false, label: '14:00', align: 'left', minWidth: 20 },
    { id: '14:30', numeric: false, label: '14:30', align: 'left', minWidth: 20 },
    { id: '15:00', numeric: false, label: '15:00', align: 'left', minWidth: 20 },
    { id: '15:30', numeric: false, label: '15:30', align: 'left', minWidth: 20 },
    { id: '16:00', numeric: false, label: '16:00', align: 'left', minWidth: 20 },
    { id: '16:30', numeric: false, label: '16:30', align: 'left', minWidth: 20 },
    { id: '17:00', numeric: false, label: '17:00', align: 'left', minWidth: 20 },
    { id: '17:30', numeric: false, label: '17:30', align: 'left', minWidth: 20 },
    { id: '18:00', numeric: false, label: '18:00', align: 'left', minWidth: 20 },
    { id: '18:30', numeric: false, label: '18:30', align: 'left', minWidth: 20 },
    { id: '19:00', numeric: false, label: '19:00', align: 'left', minWidth: 20 },
    { id: '19:30', numeric: false, label: '19:30', align: 'left', minWidth: 20 },
    { id: '20:00', numeric: false, label: '20:00', align: 'left', minWidth: 20 },
    { id: '20:30', numeric: false, label: '20:30', align: 'left', minWidth: 20 },
    { id: '21:00', numeric: false, label: '21:00', align: 'left', minWidth: 20 },
    { id: '21:30', numeric: false, label: '21:30', align: 'left', minWidth: 20 },
    { id: '22:00', numeric: false, label: '22:00', align: 'left', minWidth: 20 },
    { id: '22:30', numeric: false, label: '22:30', align: 'left', minWidth: 20 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      // if (true) {
      //   setEnableEdit(await api.getAcessos('disponibilidades', 'Alteracao'))
      // } else {
      //   setEnableEdit(await api.getAcessos('disponibilidades', 'Inclusao'))
      // }

      setComboSedes(await api.getComboData('sedes'))

      if (true) {
        getData()
      } else swal.close()
    }

    fetchData()
  }, [])

  const getData = () => {
    setUpdateType(true)
        api.get('/disponibilidades?usu_logado='+auth.user+'&fk_sed=2').then(r => {
      
          setSabadoChi(r.data.sabado)
          console.log(r.data, 'entrou data 2')
          setSelectedChi(r.data.selected)
          setDataChi(r.data.r)
         
        })
        api.get('/disponibilidades?usu_logado='+auth.user+'&fk_sed=3').then(r => {
      
          setSabadoOn(r.data.sabado)
          console.log(r.data, 'entrou data 3')
          setSelectedOn(r.data.selected)
          setData(r.data.r)
         
        })
        api.get('/disponibilidades?usu_logado='+auth.user+'&fk_sed=5').then(r => {
      
          setSabadoOn(r.data.sabado)
          console.log(r.data, 'entrou data 5')
          setSelectedOn(r.data.selected)
          setData(r.data.r)
         
        })
        api.get('/disponibilidades?usu_logado='+auth.user+'&fk_sed=1').then(r => {
          if (r.data) {
            console.log(r.data, 'entrou data 1')
            setSabadoPli(r.data.sabado)
            setSelectedPli(r.data.selected)
            setDataPli(r.data.r)
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
  }

  useEffect(() => {
    console.log(selected, sabado)
  }, [selected, sabado])

  function consistData(data) {
    let required = [
      'fk_sed',
    ]
    let labels = {
      'fk_sed': 'Sede'
    }

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!fkSed) return errors.push(labels[fieldName])
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      icon: 'question',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      swal.fire({
        html: <FrLoading text="Salvando registros..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      if (result.value) {
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/disponibilidades/save', {fk_sed: fkSed, usu_logado: auth.user, selected: selected}).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                  html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    swal.fire({
                      html: <FrLoading text="Carregando..." />,
                      showConfirmButton: false,
                      allowOutsideClick: false,
                      allowEscapeKey: false
                    })
                    api.get('/disponibilidades?usu_logado='+auth.user+'&fk_sed='+fkSed).then(r => {
                      if (r.data) {
                        if(fkSed == 1){
                          setSabadoPli(r.data.sabado)
                          setSelectedPli(r.data.selected)
                          setDataPli(r.data.r)
                        } else {
                          console.log(r.data, 'entrou data 1')
                          setSabadoOn(r.data.sabado)
                          setSelectedOn(r.data.selected)
                          setData(r.data.r)

                        }
                        swal.close()
                      }
                    })
                  }
                })
              } else {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  html: `<p style='color: ${colors.info900}'></p>`,
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/encaminhamentos/add', auxValues).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                    
                    }
                  })
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    // auxValues[e.target.name] = e.target.value;
    setFkSed(e.target.value)
    console.log(e.target.value)
    let auxValues={

    }
    if (e.target.value == 1){
      setSelected(selectedPli)
              // setData(dataPli)
      setSabado(sabadoPli)
  

    } else if (e.target.value == 2){
      setSelected(selectedChi)
              // setData(dataChi)
      setSabado(sabadoChi)
      

    } else {
      setSelected(selectedOn)
              // setData(dataOn)
      setSabado(sabadoOn)


    }
    
    console.log(selectedPli, selectedChi, selectedOn)

    // api.get('/disponibilidades?usu_logado='+auth.user+'&fk_sed='+e.target.value).then(r => {
      
    //   setSabado(r.data.sabado)
    //   console.log(r.data)
    //   setSelected(r.data.selected)
    //   setData(r.data.r)
    //   swal.close()
     
    // })

  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>Visualização de Prontuários</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        
        <form id="regForm" onSubmit={handleSubmit}>

          
          <FrSelect
            value={fkSed || ''}
            style={{ width: 430 }}
            name="FK_SED"
            // disabled
            id="FK_SED"
            label='Sede'
            data={comboSedes}
            onChange={handleChange}
          /> 
          <br/>

          AVISO: NÃO AGENDAMOS HORÁRIOS QUEBRADOS, FAVOR DISPONIBILIZAR APENAS HORÁRIOS INTEIROS (EX: 8h, 9h, 10h...)
          <br/>
          <br/>
          <FrTablePermissions regName="disponibilidades" columns={columns} searched={true} sabado={sabado} codeName="dia" page={'Permissões'} data={data} setSelected={setSelected} selected={selected} order={'order'} />

        </form>
        <div className="regButtonsDiv">
          {1==1 ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{1==0 ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegEncaminhamentos;
